import { useLoadScript } from '@react-google-maps/api';
import { Skeleton, Box } from '@mui/material';

export default function GMap() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  if (!isLoaded) return <Skeleton variant="rectangular" width="100%" height={400} />;

  return <Map />;
}

//TODO: Figure out why the pacakge wasn't working
function Map() {
  return (
    <Box width="100%" height={400}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.9634130053105!2d-92.59195282344895!3d40.18762766955053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87e80e6ff9168c4f%3A0x268900f9caf998e9!2s1005%20S%20Orchard%20St%2C%20Kirksville%2C%20MO%2063501!5e0!3m2!1sen!2sus!4v1693168220820!5m2!1sen!2sus"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
}
