import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Slide, Typography, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EmailList = () => {
  const [open, setOpen] = useState(true);
  const [formData, setFormData] = useState({ name: '', email: '', phoneNumber: '' });
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'name') {
      setNameError(value === '');
    }

    if (name === 'email') {
      setEmailError(!validateEmail(value) || value === '');
      setEmailErrorMessage('Invalid Email Format');
    }
  };

  const handleSubscribe = async () => {
    if (formData.name === '') setNameError(true);
    if (formData.email === '') {
      setEmailError(true);
      setEmailErrorMessage('Email is a required field');
    }
    if (nameError || emailError || formData.name === '' || !validateEmail(formData.email)) {
      return;
    }

    try {
      const api_key = process.env.REACT_APP_Ecomm_API_KEY;
      const response = await axios.post(process.env.REACT_APP_Ecomm_API_KEY, formData);
      console.log(response, api_key);
      setSnackbarType('success');
      setSnackbarOpen(true);
      setSnackbarMessage('You have subscribed to our email list');
      handleClose();
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.status) {
          case 400:
            // Handle other errors
            setSnackbarType('error');
            setSnackbarOpen(true);
            setSnackbarMessage('Name or Email was empty');
            break;
          case 401:
            // Handle other errors
            setSnackbarType('warning');
            setSnackbarOpen(true);
            setSnackbarMessage('There was some error on our side saving your info. Pls email us at sales@ashimupadhayallc.com');
            break;
          case 409:
            // Handle other errors
            setSnackbarType('warning');
            setSnackbarOpen(true);
            setSnackbarMessage('This email is already registered. Try a differnt one');
            break;
          // Add more cases for other error codes as needed
          default:
            // Handle other errors
            setSnackbarType('error');
            setSnackbarOpen(true);
            setSnackbarMessage('There was some error processing your request. Pls Try again later.');
            break;
        }
      } else if (error.request) {
        setSnackbarType('error');
        setSnackbarOpen(true);
        setSnackbarMessage('There was some error processing your request. Pls Try again later.');
        console.error('No response received:', error.request);
      } else {
        setSnackbarType('error');
        setSnackbarOpen(true);
        setSnackbarMessage('There was some error processing your request. Pls Try again later.');
        console.error('Error', error.message);
      }
    }
  };

  // Close the Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog id="subscribe-to-email-list" open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-labelledby="subscribe-dialog" aria-describedby="subscribe-to-email-list">
        <DialogTitle id="subscribe-dialog">Subscribe to Our Email List!</DialogTitle>
        <DialogContent>
          <Typography paragraph>Keep your finger on the pulse of cutting-edge technology and innovative marketing strategies to boost your online revenue. Sign up for our email list now!</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            name="name"
            onChange={handleChange}
            required
            error={nameError}
            helperText={nameError ? 'Name is a required field' : ''}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            name="email"
            onChange={handleChange}
            required
            error={emailError}
            helperText={emailError && emailErrorMessage}
          />
          <TextField margin="dense" id="phone" label="Phone Number (Optional)" type="tel" fullWidth name="phoneNumber" onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubscribe} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EmailList;
