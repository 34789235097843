/* eslint-disable react/prop-types */
import { Typography, Stack } from '@mui/material';
import ServiceCard from '../components/ServiceCard';
import brandProtection from '../images/brand-protection.jfif';
import contentManagement from '../images/content_management.jfif';
import digiPresenceEnhancement from '../images/digital_presence_enhancement.jfif';
import ecommIntegration from '../images/e-commerce_platform_integration.jfif';
import marketAnalysis from '../images/market_analysis.jfif';

const services = [
  {
    image: contentManagement,
    title: 'Content Management',
    description: '',
    bulletPoints: [
      {
        heading: 'E commerce Listing Optimization',
        description:
          "In today's hyper-competitive online marketplace, merely having a presence is not enough. Your product listings must be optimized to capture attention, convey value, and convert visits into sales. That's where we come in.",
      },
      {
        heading: 'Multiple platforms',
        description:
          'We will display your product offerings on Amazon, Shopify, Facebook Marketplace, and TikTok Shop. Your e-commerce listings will feature targeted keywords, high-quality images, and several additional enhancements such as compelling product descriptions, competitive pricing strategies, and expertly crafted marketing campaigns to maximize your online presence and sales potential.',
      },
    ],
  },
  {
    image: brandProtection,
    title: 'Brand Protection',
    description: '',
    bulletPoints: [
      {
        heading: 'Minimum Advertised Price (MAP) Implementation',
        description:
          "Safeguard your brand's reputation and value. We implement robust MAP policies, ensuring that your products maintain consistent pricing across platforms, preserving brand integrity. ",
      },
    ],
  },
  {
    image: digiPresenceEnhancement,
    title: 'Digital Presence Enhancement',
    description:
      ' From SEO to social media strategies, we amplify your digital footprint. Harness our digital marketing expertise to boost visibility, engage audiences, and build lasting brand loyalty.',
  },
  {
    image: marketAnalysis,
    title: 'Tech-Infused Market Analysis',
    description:
      'Dive deep into market trends and customer behaviors with our data-driven analysis. We utilize cutting-edge tech tools to glean insights, helping brands adapt and stay ahead of the curve.',
  },
  {
    image: ecommIntegration,
    title: 'E-commerce Platform Integration',
    description:
      " Seamlessly integrate your products across multiple e-commerce platforms. Our tech expertise ensures a smooth, efficient, and consistent product listing experience, maximizing your online reach. We'll sell your products on Amazon, Shopify, Facebook marketplace, and Tiktok shop",
  },
];
const OurServices = () => {
  return (
    <>
      <Typography py={2} color="text.secondary" variant="h2" textAlign="center">
        Our Services
      </Typography>
      <Stack id="services" justifyContent="space-around" spacing={{ xs: 4 }}>
        {services.map((service, index) => (
          <ServiceCard key={index} image={service.image} title={service.title} description={service.description} bulletPoints={service.bulletPoints} isReversed={index % 2 === 1} />
        ))}
      </Stack>
    </>
  );
};

export default OurServices;
