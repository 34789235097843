import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function TawkChat() {
  return (
    <div>
      <TawkMessengerReact propertyId="64ebb512a91e863a5c102059" widgetId="1h8sbmk21" />
    </div>
  );
}
export default TawkChat;
