import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

// Custom ScrollTop component
const ScrollTop = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const style = {
    position: 'fixed',
    bottom: '128px',
    right: '28px',
    zIndex: 1000, // you can set this to any value above your page's maximum z-index to ensure it's on top
  };

  return (
    <Zoom in={trigger}>
      <div onClick={scrollToTop} role="presentation" style={style}>
        <Fab color="secondary" size="large" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  );
};

export default ScrollTop;
