import React from 'react';
import { Button, Typography, Box, Stack, Container } from '@mui/material';
import heroImage from './../images/hero-image_II.webp';

function BoxWithImage() {
  return (
    <Stack>
      <Box
        sx={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          py: { xs: 10, md: 30 },
        }}
      >
        <Container maxWidth="md">
          <Typography fontWeight="fontWeightBold" gutterBottom color="white" display={{ xs: 'none', md: 'block' }} variant="h3">
            Sell Smart: Leveraging Tech & Marketing for E-commerce Success
          </Typography>
          <Typography position={{ xs: 'relative', md: 'static' }} top={-75} fontWeight="fontWeightBold" gutterBottom textAlign="center" sx={{ color: { xs: 'white' }, marginTop: 2 }} variant="h5">
            We purchase your products wholesale and amplify your brand&apos;s reach through optimized listings and targeted online advertising. Experience seamless growth in the digital marketplace
            with a partner who excels in both tech and marketing.
          </Typography>

          <Button size="large" variant="contained" color="primary" sx={{ marginTop: 3 }} href="#learn-more-section">
            Learn More
          </Button>
        </Container>
      </Box>
    </Stack>
  );
}

export default BoxWithImage;
