/* eslint-disable react/prop-types */
import React from 'react';
import { Card, CardContent, CardMedia, Typography, List } from '@mui/material';

const ServiceCard = ({ image, title, description, bulletPoints, isReversed }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: isReversed ? 'row-reverse' : 'row' },
        boxShadow: 'none',
      }}
    >
      <CardMedia component="img" sx={{ width: { xs: '100%', sm: '40%' } }} image={image} alt={title} />
      <CardContent sx={{ width: { xs: '100%', sm: '60%' } }}>
        <Typography px={1} gutterBottom align="left" variant="h3">
          {title}
        </Typography>
        {description && (
          <Typography px={2} align="left" color="text.secondary" gutterBottom paragraph>
            {description}
          </Typography>
        )}
        {bulletPoints && (
          <List>
            {bulletPoints.map((point, index) => (
              <Typography color="text.secondary" px={3} key={index} align="left" gutterBottom paragraph>
                <strong>{point.heading}: </strong> {point.description}
              </Typography>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
