import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import TawkChat from './components/TawkChat';
import Landing from './pages/Landing';
function App() {
  return (
    <ThemeProvider theme={responsiveFontSizes(createTheme())}>
      <Landing />
      <TawkChat />
    </ThemeProvider>
  );
}

export default App;
