import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faq_data = [
  {
    title: 'Who is Ashim Upadhaya LLC?',
    content:
      'We help brands accelerate their online sales. We are a licensed digital retailer in the state of Missouri specializing in e-commerce solutions. Our unique expertise lies in our background in both software engineering and digital marketing.',
  },
  {
    title: 'Why should I choose Ashim Upadhaya LLC?',
    content: 'We offer a one-stop-shop solution for your e-commerce needs, blending technical expertise with marketing savvy to provide a comprehensive service package.',
  },
  {
    title: 'How does this work?',
    content:
      'We make money by reselling your products, and do not charge fees or a percentage of ad spend. This buy/sell model makes us uniquely incentivized to drive profitable growth for your brand. As an e-commerce accelerator, we buy your products, so our capital is on the line, and we only make money when we resell your products. This structure incentivizes us to optimize your brand’s online presence, decrease costs, and only spend advertising dollars that incrementally drive sales.',
  },
  {
    title: 'Where do you sell?',
    content:
      'We will display your product offerings on Amazon, Shopify, Facebook Marketplace, and TikTok Shop. Your e-commerce listings will feature targeted keywords, high-quality images, and several additional enhancements such as compelling product descriptions, competitive pricing strategies, and expertly crafted marketing campaigns to maximize your online presence and sales potential.',
  },
  {
    title: 'Are you licensed to re-sell products?',
    content: 'We are a registered business in the state of Missouri and have all the necessary licenses to re-sell products offline/online. Please contact us for more info.',
  },
  {
    title: 'What does E-commerce Listing Optimization involve?',
    content: 'It includes keyword research, high-quality imagery, compelling descriptions, and pricing strategies to make your online listings more attractive and effective.',
  },
  {
    title: 'How do you protect my brand?',
    content: "We implement robust Minimum Advertised Price (MAP) policies to ensure consistent pricing and preserve your brand's integrity across platforms.",
  },
  {
    title: 'What is Digital Presence Enhancement?',
    content: 'This service includes a range of activities like SEO, social media marketing, and content strategies to increase your brand’s visibility and engagement online.',
  },
  {
    title: 'How do you use data in your marketing strategies?',
    content: 'We leverage data analytics to fine-tune your marketing campaigns, ensuring that your brand resonates with its target audience.',
  },
  {
    title: 'What kind of brands do you work with?',
    content: 'We partner with a wide range of brands looking to maximize their impact in the digital marketplace, from startups to established enterprises.',
  },
  {
    title: 'How can we get started?',
    content: 'Simply contact us to discuss your needs and how we can tailor our services to meet them.',
  },
  {
    title: "What's your commitment to transparency and trust?",
    content: 'We believe in open communication and honesty in all our partnerships. You can always expect complete transparency in all dealings with Ashim Upadhaya LLC.',
  },
];

const FAQComponent = () => {
  return (
    <Container id="learn-more-section" maxWidth="lg">
      <Typography color="text.secondary" sx={{ textAlign: 'center', marginBottom: 5 }} gutterBottom variant="h2">
        Frequently Asked Questions (FAQs)
      </Typography>
      {faq_data.map((faq, index) => (
        <Accordion key={index} sx={{ marginBottom: 1 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQComponent;
