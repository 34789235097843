import { Stack, Typography } from '@mui/material';
import vision from './../images/vision_statement_image.jfif';
import philosophy from './../images/philosophy_statement_image.jfif';
import founder from './../images/ashim.jpg';
import DataCard from '../components/DataCard';

const dataCardInputs = [
  {
    heading: 'Our Founder',
    image: founder,
    summary:
      'With a unique blend of technical prowess and marketing acumen, Ashim Upadhaya stands at the intersection of two worlds: Software Engineering and Digital Marketing. With a solid 3 years of tech experience and a specialized certification in digital marketing, Ashim brings a holistic approach to e-commerce solutions.',
  },
  {
    heading: 'Our Vision',
    image: vision,
    summary:
      " At Ashim Upadhaya LLC, we believe in the power of technology and marketing to transform brands' online presence. Our vision is to be the trusted partner for brands aiming to maximize their e-commerce impact. We strive to blend innovative tech solutions with savvy marketing strategies, ensuring brands shine brighter and reach further in the digital marketplace. ",
    description: '',
  },
  {
    heading: 'Our Philosophy',
    image: philosophy,
    summary:
      "At Ashim Upadhaya LLC, our philosophy is rooted in the synergy of code and creativity. We believe that in the vast digital marketplace, it's not just about being seen, but about shining with authenticity. Every brand has a unique story, and our mission is to amplify it, blending the precision of technology with the art of marketing. We champion transparency, innovation, and genuine partnerships. In a world where digital paths intertwine, we're committed to guiding brands towards their true north in e-commerce, ensuring they not only navigate but thrive.",
    description: '',
  },
];

export default function AboutUs() {
  return (
    <>
      <Typography color="text.secondary" sx={{ textAlign: 'center', marginBottom: 5 }} gutterBottom variant="h2">
        About Ashim Upadhaya LLC
      </Typography>
      <Stack id="aboutus" justifyContent="space-around" spacing={{ xs: 4 }} direction={{ xs: 'column', md: 'row' }}>
        {dataCardInputs.map(({ heading, image, summary, description }) => (
          <DataCard key={heading} heading={heading} imageUrl={image} contentSummary={summary} contentDetails={description} />
        ))}
      </Stack>
    </>
  );
}
