import React, { useState } from 'react';
import { Box, Container, Typography, TextField, Button, Stack } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import emailjs from 'emailjs-com';
import ContactInfo from './ContactInfo';

const emailConfig = {
  serviceId: 'service_al2ny6k',
  templateId: 'template_yw16vrs',
  publicKey: 'TecLnoE_9N05kqOCQ',
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar open/close
  const [snackbarType, setSnackbarType] = useState('success'); // State to control Snackbar type

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Show success Snackbar
    try {
      await emailjs.sendForm(emailConfig.serviceId, emailConfig.templateId, event.target, emailConfig.publicKey);
      setSnackbarType('success');
      setSnackbarOpen(true);
    } catch (error) {
      // Show error Snackbar
      setSnackbarType('error');
      setSnackbarOpen(true);
    }
  };
  const contactData = {
    days: 'Mon - Fri',
    hours: '9am - 5pm',
    location: 'Kirksville, Missouri',
    email: 'sales@ashimupadhayallc.com',
    phoneNumber: '+1 (314) 492-8746',
  };

  const handleInputChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  // Close the Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <Container id="contact" maxWidth="lg">
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarType}>
          {snackbarType === 'success'
            ? 'Your message has been received! Someone from our team will get back to you soon!'
            : `There was some error processing your request. Pls email us at ${contactData.email}`}
        </Alert>
      </Snackbar>
      <Stack>
        <Stack spacing={{ xs: 2, md: 8 }} direction={{ xs: 'column', md: 'row' }}>
          <Box>
            <Typography color="text.secondary" textAlign="center" gutterBottom variant="h2">
              Contact Us
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Have a question or need more information? We{"'"}re here to help. Fill out the form below and we{"'"}ll get back to you as soon as possible.
            </Typography>
            <Box component="form" noValidate sx={{ mb: 4 }} onSubmit={handleSubmit}>
              <TextField required fullWidth label="Name" margin="normal" variant="outlined" name="name" value={formData.name} onChange={handleInputChange} inputProps={{ sx: { fontSize: 16 } }} />
              <TextField
                required
                fullWidth
                label="Email"
                margin="normal"
                variant="outlined"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                inputProps={{ sx: { fontSize: 16 } }}
              />
              <TextField
                required
                fullWidth
                label="Message"
                margin="normal"
                variant="outlined"
                multiline
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                inputProps={{ sx: { fontSize: 16 } }}
              />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                Send
              </Button>
            </Box>
          </Box>
          <Box>
            <ContactInfo
              height="250px"
              width="250px"
              days={contactData.days}
              hours={contactData.hours}
              location={contactData.location}
              email={contactData.email}
              phoneNumber={contactData.phoneNumber}
            />
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};
export default ContactUs;
