import React from 'react';
import { IconButton, Stack } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
// eslint-disable-next-line no-unused-vars
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const SocialMediaLinks = () => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <IconButton color="primary" component="a" href="https://www.instagram.com/ashimupadhayallc/" target="_blank" rel="noopener noreferrer">
        <InstagramIcon fontSize="large" />
      </IconButton>
      <IconButton color="primary" component="a" href="https://www.facebook.com/ashimupadhayallc/" target="_blank" rel="noopener noreferrer">
        <FacebookIcon fontSize="large" />
      </IconButton>
      {/* <IconButton color="primary" component="a" href="https://www.linkedin.com/in/ashimupadhayallc/" target="_blank" rel="noopener noreferrer">
        <LinkedInIcon fontSize="large" />
      </IconButton> */}
    </Stack>
  );
};

export default SocialMediaLinks;
