import React from 'react';
import { Stack, Container } from '@mui/material';
import BoxWithImage from '../components/BoxWithImage';
import AboutUs from '../Sections/AboutUs';
import OurServices from '../Sections/OurServices';
import ContactUs from '../components/contactUs/ContactUs';
import FAQComponent from '../components/FAQComponent';
import SocialMediaLinks from '../components/SocialMediaLinks';
import GMap from '../components/GoogleMap';
import Header from '../components/Header';
import ScrollTop from '../components/ScrollTop';
import EmailList from '../components/EmailList';

function Landing() {
  return (
    <>
      <ScrollTop />
      <EmailList />
      <Header />
      <Stack spacing={3}>
        <BoxWithImage />
        <Stack backgroundColor={(theme) => theme.palette.grey[200]} jusitfycontent="center" alignItems="center">
          <Container maxWidth="lg">
            <Stack spacing={4}>
              <OurServices />
              <AboutUs />
              <ContactUs />
              <GMap />
              <SocialMediaLinks />
              <FAQComponent />
            </Stack>
          </Container>
        </Stack>
      </Stack>
    </>
  );
}

export default Landing;
